import React, { useState, useEffect  } from "react"
import { useTranslation } from 'react-i18next';
import { Row, Col, Container, Input, Label, Card, CardBody, Table, Button} from "reactstrap"
import MetaTags from 'react-meta-tags';

import { Link, Redirect } from "react-router-dom"
import { useHistory } from "react-router-dom";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import axios from 'axios';

//i18n
import { withTranslation } from "react-i18next"

//import logo from "../../assets/images/load3.gif"
import logo from "../../assets/images/loading_search.gif"


const DigRequest = () => {
    const { t, i18n } = useTranslation();

    const [searchRef, setSearchRef] = useState("")
    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false)
    const [resultsToPrint, setResultsToPrint] = useState(false)
    const [showAlertNoResult, setShowAlertNoResult] = useState(false)

    function handleNewProjectIdChange(e) {
      setNewProjectId(e.target.value)
    }



	const rowsRef = results.map(
        (info)=>{
            return(
                <tr onClick={()=>history.push(  "/project/" + info.id + '/' + info.version )}  style={parseInt(info.available)==0 ?{ backgroundColor: '#fbdadf'}:{}}>
                    <td>{info.ref}</td>
		    <td>{info.partName + info.partName2}</td>
                    <td>{parseInt(info.stock)}</td>
                    <td>{parseInt(info.reserved)}</td>
                    <td>{parseInt(info.available)}</td>
                    <td>{info.date}</td>
                </tr>
				)
			}
		  )



    function getStock(ref) {
      console.log("getStock")
      setLoading(true)
      console.log(loading)
      
      if (ref !== null ) {
        console.log(ref)
  
        axios({
          method: 'get',
          url: `https://` + process.env.REACT_APP_ENDPOINT_BE_API + '/stock/dig/' + ref,
        })
        .then(res => { setLoading(false); setResultsToPrint(true); console.log(res.data);  setResults(res.data)})
        .catch(function (error) { if (error.response) { setLoading(false); setResultsToPrint(false); setShowAlertNoResult(true); console.log(error.response.data); console.log(error.response.status); console.log(error.response.headers);} });

      }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("Explode availability")}</title>
                </MetaTags>
                <Container fluid>
                  <div className="page-title-box">
                      <Row className="align-items-center">
                          <Col md={8}>
                              <h6 className="page-title">{t("Stocks availability")}</h6>
                          </Col>
                      </Row>
                  </div>

                <Card>
                  <CardBody>

                  <Row className="mb-3"> 
                    <Col md={4}></Col>
                        <Col md={4}>
                          <div className="app-search d-lg-block">
                              <div className="position-relative">
                                  <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder={t("Type reference here...") } 
                                    onChange={(e)=> {setSearchRef(e.target.value)}} 
                                    onKeyPress={(e) => {
                                      if (e.key === "Enter") {
                                        getStock(searchRef);
                                      }
                                    }}/>
                                  <span className="fa fa-search"></span>
                              </div>
                          </div>
                        </Col>
                        <Col md={4}></Col>

                        </Row> 
                        <Row className="mb-3">
                        <Col md={5}></Col>
                        <Col md={2}>
                          <Button
                                  color="primary"
                                  className="btn btn-primary btn-lg w-100 waves-effect waves-light"
                                  onClick={()=> {getStock(searchRef)}}
                              >
                                  {t("Search")}
                              </Button>{" "}
                        </Col>
                        <Col md={5}></Col>
                      </Row>
                      </CardBody>
                  </Card>

                <Row>
                  <Col md={12}>

                    {showAlertNoResult ? (
                      <SweetAlert
                        title="No results"
                        type= "error"
                        showConfirm={true}
                        onConfirm={() => {
                          setShowAlertNoResult(false)
                        }}
                      >
                        The reference has not be found. Check if the reference has been written correctly.
                      </SweetAlert>
                    ) : null}



                    {loading ? (<img src={logo} alt="loading..." class="mx-auto d-block"/>) : 
                    
                    resultsToPrint ?
                    <>

                    <Card>
                      <CardBody>
                          <h4 className="card-title">Results</h4>

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>{t("Ref")}</th>
	                  <th>{t("Name")}</th>
                          <th>{t("Stock")}</th>
                          <th>{t("Reserved")}</th>
                          <th>{t("Available")}</th>
                          <th>{t("Date")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rowsRef}
                      </tbody>
                    </Table>
                  </div>




                      </CardBody>
                    </Card>
                  </> : null }
                  </Col>
                </Row>
              
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DigRequest
