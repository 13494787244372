import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
//const API_URL = "http://127.0.0.1:5000"
//const API_URL = "http://google.fr"

/*const axiosApi = axios.create({
  baseURL: API_URL,
})*/
const axiosApi = axios.create()

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => {console.log(response)
              return response},
  error => Promise.reject(error)
)

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

axios.interceptors.request.use(request => {
  console.log('Starting Request', JSON.stringify(request, null, 2))
  return request
})
export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
