import React, { useState, useEffect  } from "react"
import { useTranslation } from 'react-i18next';
import { Row, Col, Container, Input, Label, Card, CardBody, Table, Button} from "reactstrap"
import MetaTags from 'react-meta-tags';

import { Link, Redirect } from "react-router-dom"
import { useHistory } from "react-router-dom";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import axios from 'axios';

//i18n
import { withTranslation } from "react-i18next"

//import logo from "../../assets/images/load3.gif"
import logo from "../../assets/images/loading_search.gif"


const StockRequest = () => {
    const { t, i18n } = useTranslation();

    const [searchRef, setSearchRef] = useState("")
    const [results, setResults] = useState({})
    const [loading, setLoading] = useState(false)
    const [resultsToPrint, setResultsToPrint] = useState(false)
    const [showAlertNoResult, setShowAlertNoResult] = useState(false)

    function handleNewProjectIdChange(e) {
      setNewProjectId(e.target.value)
    }

    /*const rowsResult = results.map(
        (data)=>{
            return(
                <tr>
                    <td>{data.stock}</td>
                    <td>{data.reserved}</td>
                    <td>{data.available}</td>
                </tr>
            )
        }
  )*/

  const RowResult = (props) => {
    return (
      <>
        <tr>
            <td>{parseInt(props.results.stock)}</td>
            <td>{parseInt(props.results.reserved)}</td>
            <td>{parseInt(props.results.available)}</td>
        </tr>
      </>
    )
  }


  /*const rowsResult = (results) => {return (
            <tr>
                <td>{results.stock}</td>
                <td>{results.reserved}</td>
                <td>{results.available}</td>
            </tr>
            )}*/

    /*const RenderRow = (props) =>{
      return props.keys.map((key, index)=>{
        return <td key={props.data[key]}>{props.data[key]}</td>
      })
     }

    rowsProject = items.map((row, index)=>{ return <tr key={index}> <RenderRow key={index} data={row} keys={keys}/></tr> })*/


    function getStock(ref) {
      console.log("getStock")
      setLoading(true)
      console.log(loading)
      
      if (ref !== null ) {
        console.log(ref)
  
        /*axios.interceptors.request.use(request => {
          console.log('Starting Request', JSON.stringify(request, null, 2))
          return request
        })*/

        axios({
          method: 'get',
          url: `https://` + process.env.REACT_APP_ENDPOINT_BE_API + '/stock/get/' + ref,
        })
        .then(res => { setLoading(false); setResultsToPrint(true); console.log(res.data);  setResults(res.data)})
        .catch(function (error) { if (error.response) { setLoading(false); setResultsToPrint(false); setShowAlertNoResult(true); console.log(error.response.data); console.log(error.response.status); console.log(error.response.headers);} });

      }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("Stock request")}</title>
                </MetaTags>
                <Container fluid>
                  <div className="page-title-box">
                      <Row className="align-items-center">
                          <Col md={8}>
                              <h6 className="page-title">{t("Stocks availability")}</h6>
                          </Col>
                      </Row>
                  </div>

                <Card>
                  <CardBody>

                  <Row className="mb-3"> 
                    <Col md={4}></Col>
                        <Col md={4}>
                          <div className="app-search d-lg-block">
                              <div className="position-relative">
                                  <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder={t("Type reference here...") } 
                                    onChange={(e)=> {setSearchRef(e.target.value)}} 
                                    onKeyPress={(e) => {
                                      if (e.key === "Enter") {
                                        getStock(searchRef);
                                      }
                                    }}/>
                                  <span className="fa fa-search"></span>
                              </div>
                          </div>
                        </Col>
                        <Col md={4}></Col>

                        </Row> 
                        <Row className="mb-3">
                        <Col md={5}></Col>
                        <Col md={2}>
                          <Button
                                  color="primary"
                                  className="btn btn-primary btn-lg w-100 waves-effect waves-light"
                                  onClick={()=> {getStock(searchRef)}}
                              >
                                  {t("Search")}
                              </Button>{" "}
                        </Col>
                        <Col md={5}></Col>
                      </Row>
                      </CardBody>
                  </Card>

                <Row>
                  <Col md={12}>

                    {showAlertNoResult ? (
                      <SweetAlert
                        title="No results"
                        type= "error"
                        showConfirm={true}
                        onConfirm={() => {
                          setShowAlertNoResult(false)
                        }}
                      >
                        The reference has not be found. Check if the reference has been written correctly.
                      </SweetAlert>
                    ) : null}



                    {loading ? (<img src={logo} alt="loading..." class="mx-auto d-block"/>) : 
                    
                    resultsToPrint ?
                    <>

                    <Card>
                      <CardBody>
                          <h4 className="card-title">Results</h4>


                          <Row className="mb-5 mt-3 ms-2">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Product Name
                            </label>
                            <div className="col-md-8">
                              <input
                                className="form-control"
                                type="text"
                                defaultValue=""
                                value={results.partName + results.partName2}
                                readOnly
                              />
                            </div>
                          </Row>

                          <Row className="mb-1 ms-2">
                        <div className="mb-2">
                          <div className="row">
                            <div className="col-md-4">
                              <div>
                                <p className="text-muted">Stock</p>
                                <h5 className="mb-3">{parseInt(results.stock)}</h5>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div>
                                <p className="text-muted">Reserved</p>
                                <h5 className="mb-3">{parseInt(results.reserved)}</h5>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div>
                                <p className="text-muted">Available</p>
                                <h5 className="mb-3">{parseInt(results.available)}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </> : null }
                  </Col>
                </Row>
              
                </Container>
            </div>
        </React.Fragment>
    )
}

export default StockRequest





{/* <ul className="message-list">
<li>
  <div className="col-mail">
      Client ID
  </div>
  <div className="col-mail">
      Revision
  </div>
  <div className="col-mail">
    Client
  </div>
  <div className="col-mail">
  Gearbox
  </div>
  <div className="col-mail">
  Kinematic
  </div>
  
  Last modified
  
</li>


<li>
  <div className="col-mail">
  13546
  </div>
  <div className="col-mail">
      A
  </div>
  <div className="col-mail">
    Baudoin
  </div>
  <div className="col-mail">
  W4400
  </div>
  <div className="col-mail">
  CEW
  </div>
  
  12/12/21
  
</li>

<li>
  <div className="col-mail col-mail-1">
    <div className="checkbox-wrapper-mail">
      <Input type="checkbox" id="chk19" />
      <Label htmlFor="chk19" className="toggle"/>
    </div>
    <Link to="#" className="title">
      Peter, me (3)
    </Link>
    <span className="star-toggle far fa-star"/>
  </div>
  <div className="col-mail col-mail-2">
    <Link to="#" className="subject">
      Hello –{" "}
      <span className="teaser">
        Trip home from Colombo has been arranged, then Jenna
        will come get me from Stockholm. :)
      </span>
    </Link>
    <div className="date">Mar 6</div>
  </div>
</li>
</ul> */}









{/* <ul className="message-list">
<li>
  <div className="col-mail col-mail-1">
      Client ID
  </div>
  <div className="col-mail col-mail-1">
      Revision
  </div>
  <div className="col-mail col-mail-1">
    Client
  </div>
  <div className="col-mail col-mail-1">
  Gearbox
  </div>
  <div className="col-mail col-mail-1">
  Kinematic
  </div>
  
  Last modified
  
</li>


<li>
  <div className="col-mail col-mail-1">
  13546
  </div>
  <div className="col-mail col-mail-1">
      A
  </div>
  <div className="col-mail col-mail-1">
    Baudoin
  </div>
  <div className="col-mail col-mail-1">
  W4400
  </div>
  <div className="col-mail col-mail-1">
  CEW
  </div>
  
  12/12/21
  
</li>

<li>
  <div className="col-mail col-mail-1">
    <div className="checkbox-wrapper-mail">
      <Input type="checkbox" id="chk19" />
      <Label htmlFor="chk19" className="toggle"/>
    </div>
    <Link to="#" className="title">
      Peter, me (3)
    </Link>
    <span className="star-toggle far fa-star"/>
  </div>
  <div className="col-mail col-mail-2">
    <Link to="#" className="subject">
      Hello –{" "}
      <span className="teaser">
        Trip home from Colombo has been arranged, then Jenna
        will come get me from Stockholm. :)
      </span>
    </Link>
    <div className="date">Mar 6</div>
  </div>
</li>
</ul> */}
