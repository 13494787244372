import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Stock request
import StockRequest from "../pages/StockRequest/index"
import DigRequest from "../pages/DigRequest/index"

const userRoutes = [
  { path: "/stockRequest", component: StockRequest },

  { path: "/digRequest", component: DigRequest },

  // // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/stockRequest" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
]

export { userRoutes, authRoutes }
